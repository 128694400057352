import React from "react"
import { Link } from "gatsby"

import RecentPosts from "../blog/recentPosts"
import SocialLinks from "../socialLinks"
import AboutUsSideBar from "../aboutUsSidebar"

const ResourceSideBar = ({ hrItems, safetyItems, blogItems }) => (
  <div className="resource-sidebar">
    <span className="title-text">More Handy Resources:</span>
    <div className="sidebar-section-wrapper divider">
      <Link to="/tools/hr-tool-kit">
      <span className="title-text blue">HR Tool Kit</span>
      </Link>

      <ul>
        {hrItems.map(hrResource => {
          return (
            <li>
              <Link to={`/tools/${hrResource.slug}`}>
                <div className="recent-post-item-wrapper">
                  <span> {hrResource.Title}</span>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
    <div className="sidebar-section-wrapper divider">
      <Link to="/tools/diy-safety-system">
      <span className="title-text blue">DIY Safety System</span>
      </Link>
      <ul>
        {safetyItems.map(safetyResource => {
          return (
            <li>
              <Link to={`/tools/${safetyResource.slug}`}>
                <div className="recent-post-item-wrapper">
                  <span> {safetyResource.Title}</span>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
    <div className="sidebar-section-wrapper divider">
      <Link to="/blog">
      <span className="title-text blue">From Our Blog</span>
      </Link>

      <RecentPosts articles={blogItems} />
    </div>

    <AboutUsSideBar />
    <SocialLinks />
  </div>
)

export default ResourceSideBar
