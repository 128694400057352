import React from "react"
import PropTypes from "prop-types"
import InfoBlue from "../../assets/info_icon_blue.svg"

const getCollectionName = collectionID => {
  switch (collectionID) {
    case "hr":
      return "HR Tool Kit"
    case "safety":
      return "DIY Company Health And Safety Program"
    case "safetysign":
      return "DIY Company Health And Safety Program"
    case "construction":
      return "Free Excel Construction Template"
    default:
      return "HR Tool Kit"
  }
}

const getCollectionLink = collectionID => {
  switch (collectionID) {
    case "hr":
      return "/tools/hr-tool-kit"
    case "safety":
      return "/tools/diy-safety-system/"
    case "safetysign":
      return "/tools/diy-safety-system/"
    case "construction":
      return "/tools/free-excel-construction-templates/"
    default:
      return "/tools/hr-tool-kit"
  }
}

const PartOfCollectionCallout = ({ resourceType, collectionID }) => (
  <div className="collection-callout">
    <img src={InfoBlue} alt="check mark" />
    <h4>
      {`This Downloadable ${resourceType} Is Part Of Our `}
      <a href={getCollectionLink(collectionID)}>
        Free {getCollectionName(collectionID)}
      </a>{" "}
      Collection
    </h4>
  </div>
)

PartOfCollectionCallout.propTypes = {
  collectionID: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
}

export default PartOfCollectionCallout
