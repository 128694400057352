import React from "react"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleWrapper from "../components/layout/articleWrapper"
import DownloadButton from "../components/resources/downloadButton"
import PartOfCollectionCallout from "../components/resources/partOfCollectionCallout"
import Disclaimer from "../components/resources/disclaimer"
import ResourceSideBar from "../components/resources/resourceSideBar"
import Hero from "../components/home/hero"
import Image from "gatsby-image"
import { resourceRenderers } from "../constants/mdxRenderers"

import {
  SIGN_UP_LINK,
  LOGIN_LINK,
  APP_STORE_LINK,
  PLAY_STORE_LINK,
} from "../constants/linkConstants"

export const query = graphql`
  query Resource($slug: String!) {
    latestArticles: allStrapiArticle(
      sort: { fields: date, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        slug
        date(formatString: "MMMM Do, YYYY")
      }
    }
    safetyResources: allStrapiResource(
      filter: {
        resource_category: { type: { eq: "safety" } }
        slug: { ne: $slug }
      }
      limit: 3
    ) {
      nodes {
        slug
        Title
      }
    }
    hrResources: allStrapiResource(
      filter: { resource_category: { type: { eq: "hr" } }, slug: { ne: $slug } }
      limit: 3
    ) {
      nodes {
        slug
        Title
      }
    }
    resource: strapiResource(slug: { eq: $slug }) {
      slug
      filename
      introtext
      thumbnailblurb
      resourceastext
      predownloadblurb
      id
      resourceType
      SEO {
        description
        titleseo
      }
      Title
      type
      resource_category {
        type
      }
      image {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thumb {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const Resource = ({ data }) => {
  const {
    resource: {
      Title,
      filename,
      type: fileType,
      image,
      introtext,
      predownloadblurb,
      resourceastext,
      resourceType,
      SEO: { description, titleseo },
      resource_category: { type },
      slug,
    },
    hrResources: { nodes: hrItems },
    safetyResources: { nodes: safetyItems },
    latestArticles: { nodes: blogItems },
  } = data
  const signupLink = `${SIGN_UP_LINK}?utm_source=seo_content&utm_medium=organic&utm_campaign=resources&utm_content=${slug}`
  const appStoreLink = `${APP_STORE_LINK}?utm_source=seo_content&utm_medium=organic&utm_campaign=resources&utm_content=${slug}`
  const playStoreLink = `${PLAY_STORE_LINK}?utm_source=seo_content&utm_medium=organic&utm_campaign=blog&utm_content=${slug}`
  const loginLink = `${LOGIN_LINK}?utm_source=seo_content&utm_medium=organic&utm_campaign=resources&utm_content=${slug}`
  return (
    <Layout signupLink={signupLink} loginLink={loginLink}>
      <SEO title={titleseo} description={description} />
      <ArticleWrapper style={{ marginBottom: 0 }}>
        <article>
          <h1>{Title}</h1>
          <PartOfCollectionCallout
            collectionID={type}
            resourceType={resourceType}
          />
          <Markdown children={introtext} components={resourceRenderers} />
          <Markdown
            children={predownloadblurb}
            components={resourceRenderers}
          />
          <p>
            <b>
              <i>The Team At SINC</i>
            </b>
          </p>
          <Image
            style={{
              maxWidth: 580,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2rem",
              marginBottom: "1rem",
              maxHeight: resourceType === "Sign" ? 400 : undefined,
            }}
            imgStyle={{ objectFit: "contain" }}
            fluid={image.childImageSharp.fluid}
            alt={titleseo}
          />
          <DownloadButton type={fileType} fileName={filename} />
          {resourceastext && (
            <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
              <Markdown
                children={resourceastext}
                components={resourceRenderers}
              />
            </div>
          )}
          <Disclaimer />
        </article>
        <ResourceSideBar
          blogItems={blogItems}
          hrItems={hrItems}
          safetyItems={safetyItems}
        />
      </ArticleWrapper>
      <Hero
        signupLink={signupLink}
        appStoreLink={appStoreLink}
        playStoreLink={playStoreLink}
      />
    </Layout>
  )
}

export default Resource
