import React from "react"

const Disclaimer = () => (
  <div
    style={{
      padding: 20,
      borderTop: "1px solid rgba(0,0,0,0.1)",
    }}
  >
    <p style={{ fontSize: "0.8rem" }}>
      This sample resource is provided to you free by SINC BUSINESS CORPORATION
      to help with your compliance efforts and includes no warranty as to its
      suitability for any particular task. It is only our opinion based on our
      experience and should not be considered legal advice.
    </p>
  </div>
)

export default Disclaimer
