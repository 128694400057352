import React, { Component } from "react"
import PropTypes from "prop-types"
import firebase from "firebase/app"
import "firebase/storage"
import fileSaver from "file-saver"

import ExcelLogo from "../../assets/excel_logo.svg"
import PDFLogo from "../../assets/pdf_logo.svg"
import WordLogo from "../../assets/word_logo.svg"
import DownloadIconWhite from "../../assets/download_icon_white.svg"

const LOGO_TRACKING_ID = "file_download_resource_image"
const BUTTON_TRACKING_ID = "file_download_resource_button"

const getLogo = (type, downloadFile) => {
  switch (type) {
    case "excel":
      return (
        <img
          id={LOGO_TRACKING_ID}
          onClick={downloadFile}
          style={{ cursor: "pointer" }}
          src={ExcelLogo}
          alt="Excel Logo"
        />
      )
    case "word":
      return (
        <img
          id={LOGO_TRACKING_ID}
          onClick={downloadFile}
          style={{ cursor: "pointer" }}
          src={WordLogo}
          alt="Word Logo"
        />
      )
    case "pdf":
      return (
        <img
          id={LOGO_TRACKING_ID}
          onClick={downloadFile}
          style={{ cursor: "pointer" }}
          src={PDFLogo}
          alt="PDF Logo"
        />
      )
    default:
      return (
        <img
          id={LOGO_TRACKING_ID}
          onClick={downloadFile}
          style={{ cursor: "pointer" }}
          src={ExcelLogo}
          alt="Excel Logo"
        />
      )
  }
}

const Button = ({ onDownload, type, loading }) => (
  <button
    className={loading ? "btn download loading" : "btn download"}
    id={BUTTON_TRACKING_ID}
    onClick={onDownload}
  >
    {getLogo(type, onDownload)}

    {loading ? "Downloading..." : `Download ${type.toUpperCase()} File`}
    <img src={DownloadIconWhite} alt="download" />
  </button>
)

class DownloadButton extends Component {
  constructor(props) {
    super(props)
    this.downloadFile = this.downloadFile.bind(this)
    this.state = {
      loading: false,
    }
  }

  downloadFile() {
    const { fileName } = this.props
    this.setState({
      loading: true,
    })
    if (!firebase.apps.length) {
      var config = {
        apiKey: "AIzaSyA6m3EYGIWBS0CBvHwrerPMQJ0BC26sZ6M",
        authDomain: "sinc-public-site.firebaseapp.com",
        databaseURL: "https://sinc-public-site.firebaseio.com",
        projectId: "sinc-public-site",
        storageBucket: "sinc-public-site.appspot.com",
        messagingSenderId: "763720097183",
      }
      firebase.initializeApp(config)
    }
    var storage = firebase.storage()
    var imageRef = storage.ref(fileName)

    imageRef
      .getDownloadURL()
      .then(url => {
        // This can be downloaded directly:
        var xhr = new XMLHttpRequest()
        xhr.responseType = "blob"
        xhr.onload = function (event) {
          var blob = xhr.response
          fileSaver.saveAs(blob, fileName)
        }
        xhr.open("GET", url)
        xhr.send()
        setTimeout(() => {
          console.log("timeout function")
          this.setState({
            loading: false,
          })
        }, 1500)
      })
      .catch(function (error) {
        // Handle any errors
      })
  }

  render() {
    const { alt, inline, previewImage, type } = this.props
    return (
      <>
        {inline ? (
          <div className="download-resource-wrapper">
            <Button
              loading={this.state.loading}
              onDownload={this.downloadFile}
              type={type}
            />
          </div>
        ) : (
          <div className="download-resource-wrapper">
            <div style={{ textAlign: "center" }}>
              <img
                alt={alt}
                style={{ height: "100%", width: "auto" }}
                src={previewImage}
              />
            </div>
            <Button
              loading={this.state.loading}
              onDownload={this.downloadFile}
              type={type}
            />
          </div>
        )}
      </>
    )
  }
}

DownloadButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  previewImage: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  inline: PropTypes.bool,
}

export default DownloadButton
